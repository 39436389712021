<template>
  <div>
    <Tab :items="items" class="my-10" />
    <Card>
      <template slot="outer">
        <PageTopOuter icon="border_inner" label="All files" />
      </template>
      <CardBody class="p-3">
        <router-view />
      </CardBody>
    </Card>
    <Dialog>
      <CreateNewMaterial />
    </Dialog>
  </div>
</template>

<script>
import Tab from "@/components/atom/Tab";
import PageTopOuter from "@/components/molecule/PageTopOuter";
import { Card, CardBody, Dialog } from "@/components";
import CreateNewMaterial from "@/components/molecule/material/CreateNewMaterial";
import { fromPlatform } from "@/data/material/dashboard";
export default {
  components: {
    Tab,
    Card,
    CardBody,
    PageTopOuter,
    Dialog,
    CreateNewMaterial
  },
  data() {
      return {
          items: fromPlatform,
      }
  },
};
</script>
