<template>
  <Table :items="materialDashboard">
    <template slot="row" slot-scope="{ item }" md-selectable="single">
      <md-table-cell md-label="FILE_ID">{{ item.fileId }}</md-table-cell>
      <md-table-cell md-label="FILE NAME" class="text-victoria">{{ item.fileName }}</md-table-cell>
      <md-table-cell md-label="FILE TYPE">{{ item.fileType }}</md-table-cell>
      <md-table-cell md-label="COLLECT FROM" class="text-victoria">{{ item.collectFrom }}</md-table-cell>
      <md-table-cell md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
      <md-table-cell md-label="DOWNLOAD ACCESS">
         <md-icon class="text-green">{{ item.downloadAccess ? 'check': ''}}</md-icon>
      </md-table-cell>
      <md-table-cell md-label="SPECIALITY" class="text-green">
         <md-icon class="text-green">{{ item.speciality ? 'check': ''}}</md-icon>
      </md-table-cell>
      <md-table-cell md-label="USED FOR">{{ item.usedFor }}</md-table-cell>
    </template>
  </Table>
</template>

<script>
import { Table } from "@/components";
import data from "@/data/material/data"
export default {
  data() {
    return {
      materialDashboard: data.materialDashboard,
    };
  },
  components: {
      Table
  }
};
</script>

<style></style>
